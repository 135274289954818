import React from 'react'
import T from 'prop-types'
import {Link} from 'gatsby'
import Markdown from 'react-markdown'
import SEO from 'Layout/SEO'
import styles from './IndexPage.module.css'

export const links = [
  ['Портфолио', '/portfolio/'],
  ['Воркшопы', '/workshops/'],
]

export const IndexPageTemplate = ({indexTitle, ...props}) => (
  <>
    <Markdown className={styles.title} source={indexTitle}/>
    <nav className={styles.links}>
      {links.map(([title, to], index) => (
        <Link key={index} className={styles.link} to={to}>
          <span>{title}</span>
        </Link>
      ))}
    </nav>
  </>
)

IndexPageTemplate.propTypes = {indexTitle: T.string.isRequired}

export const IndexPage = props => (
  <>
    <SEO {...props}/>
    <IndexPageTemplate {...props} {...props.data.markdownRemark.frontmatter}/>
  </>
)

IndexPage.propTypes = {
  data: T.shape({
    markdownRemark: T.shape({
      frontmatter: T.object,
    }),
  }),
}

export default IndexPage
