import IndexPage from './IndexPage'
import {graphql} from 'gatsby'

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        indexTitle
      }
    }
  }
`
